<template>
  <title-wrapper :title="$t('components.energyStorage.title')">
    <v-container class="mx-0 px-0"
      ><v-row>
        <v-col cols="12" sm="4" v-for="category in categoriesCards" :key="category.name">
          <choose-panel
            panelHeight="17rem"
            :to="{ name: 'EnergyStorage', query: { subCategory: category.value } }"
            :icon="category.icon"
            iconSize="5rem"
          >
            {{ category.name }}
          </choose-panel>
        </v-col>
      </v-row></v-container
    >
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../components/TitleWrapper.vue';
import ChoosePanel from '../components/ChoosePanel.vue';
import { categoriesComputedMixin } from '../mixins/categoriesComputedMixin';
export default {
  components: { TitleWrapper, ChoosePanel },
  name: 'EnergyStorageCategories',
  mixins: [categoriesComputedMixin],
  methods: {
    goFoward() {
      this.$router.push({ name: 'TransactionSummary' });
    },
  },
  computed: {
    categoriesCards() {
      return [
        {
          name: this.$i18n.t('helpers.allProducts'),
          value: this.categoriesObj.ENERGY_STORAGE.value,
          icon: '$energyStorageIcon',
        },

        {
          name: this.$i18n.t('statics.subCategoriesList.CONTROLE_MODULE'),
          value: this.subCategoriesObj.CONTROLE_MODULE.value,
          icon: '$controleModuleIcon',
        },
        {
          name: this.$i18n.t('statics.subCategoriesList.BATTERY'),
          value: this.subCategoriesObj.BATTERY.value,
          icon: '$batteryIcon',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
