<template>
  <title-wrapper :title="$t('components.setPassword.title')">
    <v-container class="white pa-10">
      <set-account-password
        :button-text="$t('components.setPassword.buttonText')"
        :loading="loading"
        v-on:click="setPassword"
      ></set-account-password>
    </v-container>
  </title-wrapper>
</template>
<script>
import SetAccountPassword from '../../components/SetAccountPassword.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import AuthService from '../../services/AuthService';
export default {
  components: { TitleWrapper, SetAccountPassword },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async setPassword(password) {
      this.loading = true;
      try {
        const token = this.$route.params.token;
        await AuthService.resetPassword(token, password);
        this.$router.push({ name: 'PasswordChanged' });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
