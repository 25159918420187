<template>
  <title-wrapper :title="$t('components.heatPumps.title')">
    <heat-pumps-list></heat-pumps-list>
    <v-row class="mt-3">
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <primary-button :onClick="goFoward">{{ $t('helpers.summary') }}</primary-button>
      </v-col>
    </v-row>
  </title-wrapper>
</template>
<script>
import HeatPumpsList from '../components/HeatPumpsList.vue';
import TitleWrapper from '../components/TitleWrapper.vue';
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
export default {
  components: { TitleWrapper, HeatPumpsList, PrimaryButton },
  name: 'HeatPumps',
  methods: {
    goFoward() {
      this.$router.push({ name: 'TransactionSummary' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
