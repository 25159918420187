<template>
  <title-wrapper :title="$t('components.main.title')">
    <v-container class="mx-0 px-0"
      ><v-row class="mb-10" justify="center"
        ><v-col
          cols="12"
          sm="4"
          class="choosePanelCol"
          v-for="option in optionsCards"
          :key="option.type ? option.to + option.type : option.to"
        >
          <div @click="setWizardType(option.type)">
            <choose-panel
              :to="{ name: option.to }"
              :iconSize="iconSize"
              :icon="option.icon"
              :panelHeight="setPanelHeight"
            >
              {{ option.name }}
              <p
                v-if="option.to === 'StartWizard'"
                class="primary--text text-uppercase text-subtitle-2 mt-1 assistant px-2"
              >
                <v-icon class="mb-1 mr-1" size="20">$chatIcon</v-icon>
                {{ $t('components.main.virtualAssistant') }}
              </p>
            </choose-panel>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../components/TitleWrapper.vue';
import ChoosePanel from '../components/ChoosePanel.vue';
import { wizardTypes } from '../static/wizardTypes';
import { wizardTypeComputed } from '../store/helper';

export default {
  components: { TitleWrapper, ChoosePanel },
  computed: {
    ...wizardTypeComputed,
    optionsCards() {
      return [
        {
          to: 'Categories',
          icon: '$shoppingIcon',
          name: this.$i18n.t('components.main.fastShoping'),
        },
        {
          to: 'StartWizard',
          icon: '$roofConstructionIcon',
          name: this.$i18n.t('components.main.roofConstruction'),
          type: 'constructionCalculator',
        },
        {
          to: 'Sets',
          icon: '$setsComboIcon',
          name: this.$i18n.t('components.main.sets'),
        },
        {
          to: 'EnergyStorageCategories',
          icon: '$energyStorageIcon',
          name: this.$i18n.t('components.main.energyStorage'),
        },
        {
          to: 'HeatPumps',
          icon: '$heatPumpIcon',
          name: this.$i18n.t('components.main.heatPumps'),
        },
      ];
    },
    iconSize() {
      return this.$vuetify.breakpoint.sm ? 80 : 110;
    },
    setPanelHeight() {
      return this.$vuetify.breakpoint.sm ? '16rem' : '18rem';
    },
  },
  methods: {
    async setWizardType(type = 'assistant') {
      await this.$store.dispatch('setWizardType', wizardTypes[type]);
    },
  },
};
</script>
<style lang="scss" scoped>
.assistant {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}
</style>
