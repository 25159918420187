<template>
  <router-link :to="to">
    <v-card
      :height="panelHeight"
      class="d-flex flex-column white my-card align-center"
      :class="optionsRoute ? 'main-options' : 'categories'"
    >
      <v-icon :size="iconSize" class="mb-4" color="primary">{{ icon }}</v-icon>
      <h2 class="font-weight-bold text-center"><slot></slot></h2>
    </v-card>
  </router-link>
</template>
<script>
export default {
  name: 'ChoosePanel',
  props: ['icon', 'to', 'iconSize', 'panelHeight'],
  methods: {
    optionsRoute() {
      return this.$route.name === 'Options';
    },
  },
};
</script>
<style lang="scss" scoped>
.my-card {
  transition: all 0.2s ease-out !important;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 11px 18px -2px rgba(0, 0, 0, 0.3) !important;
  }
}
.main-options {
  position: relative;
  padding: 2.5rem 1rem 4.2rem 1rem;
  justify-content: space-between;
}

.categories {
  justify-content: flex-end;
  padding: 1rem;
}
</style>
